<template>
  <div class="lecturer">
    <div class="content">
      <ModTit
        :title="
          module.menuJsonObj
            ? module.menuJsonObj[langNamesEnum[languageActive]]
            : module.title
        "
        :moreText="$t('Home.SeeMore')"
        :more="{ path: '/lecturer' }"
      />
      <div class="card">
        <template v-if="dataSource.length">
          <a
            class="item"
            v-for="(item, index) in dataSource"
            :key="index"
            :href="`/lecturer/detail?id=${item.lecturerId}${
              newWindow == 1 ? '&ddtab=true' : ''
            }`"
            :target="newWindow == 1 ? '_blank' : '_self'"
          >
            <div class="cover">
              <img :src="item.portrait" alt="portrait" v-if="item.portrait" />
              <img
                src="@/assets/image/teacher_avatar.png"
                alt="portrait"
                v-else
              />
              <span class="level">{{ item.levelName }}</span>
            </div>
            <div class="info">
              <div class="name">
                <OpenData
                  type="userName"
                  :openid="item.lecturerName"
                  v-if="item.lecturerType == 1"
                /><span v-else>{{ item.lecturerName }}</span>
              </div>
              <div class="intro-box">
                <div class="intro">
                  {{ $t("Home.introduce")
                  }}<!-- 介绍 -->：{{ item.intro ? item.intro : $t("Home.None")
                  }}<!-- 暂无 -->
                </div>
              </div>
            </div>
          </a>
        </template>
        <div class="empty-box" v-else>
          <a-empty
            :image="require('@/assets/image/empty.png')"
            :image-style="{
              height: '144px',
            }"
          >
            <template #description>
              <div class="empty-tips">{{ $t("Pub_Lab_NoData") }}</div>
            </template>
          </a-empty>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import OpenData from "@/components/OpenData.vue";
import ModTit from "@/components/layout/ModTit.vue";
export default {
  name: "lecturer",
  components: {
    OpenData,
    ModTit,
  },
  props: {
    module: {
      type: Object,
      default: () => {
        return {};
      },
    },
    langNamesEnum: {
      type: Object,
      default: () => {
        return {};
      },
    },
    languageActive: {
      type: String,
      default: "",
    },
    dataSource: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  setup() {
    const store = useStore();
    return {
      newWindow: store.getters.companyInfo.useNewWindow,
    };
  },
};
</script>

<style lang="less" scoped>
.lecturer {
  .content {
    .mixinWrap();
    margin-bottom: -20px;
    .card {
      .mixinFlex();
      flex-wrap: wrap;
      .item {
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(148, 148, 148, 0.1);
        cursor: pointer;
        width: 183.33px;
        min-height: 294px;
        margin-right: 20px;
        position: relative;
        &:nth-child(6n) {
          margin-right: 0;
        }
        .cover {
          .mixinImgWrap(183px; 244px);
          border-radius: 8px 8px 0 0;
          position: relative;
          .level {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 30px;
            padding: 2px 12px;
            color: #fff;
            font-size: 14px;
            .mixinEllipsis(26px);
            z-index: 1;
            background-color: rgba(55, 125, 255, 0.7);
          }
        }
        .info {
          position: absolute;
          top: 244px;
          left: 0;
          right: 0;
          z-index: 1;
          background-color: #fff;
          border-radius: 0 0 8px 8px;
          box-shadow: 0 0 10px rgba(148, 148, 148, 0.1);
          .name {
            color: #333;
            font-size: 16px;
            font-weight: 600;
            .mixinEllipsis(26px);
            padding: 12px;
          }
          .intro-box {
            padding: 0 12px;
            height: 0;
            transition: all 0.3s ease;
            .intro {
              color: #999;
              font-size: 14px;
              font-weight: 400;
              .mixinEllipsis(78px; 3);
              min-height: 0;
              height: 0;
              transition: all 0.3s ease;
            }
          }
        }
        &:hover {
          .intro-box {
            height: 90px;
            padding: 0 12px 12px;
            .intro {
              min-height: 78px;
              height: 78px;
            }
          }
        }
      }
    }
  }
}
.empty-box {
  padding: 70px 0;
  width: 100%;
  .empty-tips {
    color: #999;
    font-size: 14px;
    margin-top: 15px;
  }
}
</style>
